@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    font-family: 'Montserrat', sans-serif;
  }

  .page-bg {
    background: url('./assets/img/dashboard-bg-gradient.png') no-repeat center;
    background-size: cover;
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: -1;
  }

  .container {
    width: 100%;
  }

  .input-shadow {
    box-shadow: 3px 4px 30px 3px rgba(0, 0, 0, 0.1);
  }
  .pulsing {
    animation: pulsing 1s infinite;
    animation-duration: 1s;
  }
  .pulsing-border {
    animation: pulsing-border 1s infinite;
    animation-duration: 1s;
  }
  @keyframes pulsing {
    from {
      width: 0px;
      height: 0px;
    }
    50% {
      width: 74px;
      height: 37px;
    }
    to {
      width: 0px;
      height: 0px;
    }
  }
  @keyframes pulsing-border {
    from {
      border-width: 0;
    }
    50% {
      border-width: 8px;
    }
    to {
      border-width: 0;
    }
  }
}

.feature-bg-1 {
  background: url(./assets/img/features-1-bg.png) no-repeat right;
  background-size: contain;
}

.feature-bg-2 {
  background: url(./assets/img/features-2-bg.png) no-repeat right;
  background-size: contain;
}

.feature-bg-3 {
  background: url(./assets/img/features-3-bg.png) no-repeat right;
  background-size: contain;
}

.feature-bg-4 {
  background: url(./assets/img/features-4-bg.png) no-repeat right;
  background-size: contain;
}

.card-img {
  background: url(./assets/img/card.png) center no-repeat;
  background-size: cover;
}
